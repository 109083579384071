<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ requestTotal }}</h2>
                <p class="fontsize-sm m-0">
                  Total Request Created
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ requestCancelled }}</h2>
                <p class="fontsize-sm m-0">
                  Total Request Cancelled
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ requestDisapproved }}</h2>
                <p class="fontsize-sm m-0">
                  Total Request Disapproved
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ requestCompleted }}</h2>
                <p class="fontsize-sm m-0">
                  Total Request Completed
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AuditorDashboardService } from '@/services'

export default {
  name: 'AuditorDashboard',

  middleware: ['auth', 'auditor'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      requestTotal: 0,
      requestCancelled: 0,
      requestDisapproved: 0,
      requestCompleted: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {
      await AuditorDashboardService.get().then(({ data }) => {
        this.requestTotal = data.request_total
        this.requestCancelled = data.request_cancelled
        this.requestDisapproved = data.request_disapproved
        this.requestCompleted = data.request_completed
      })
    }
  }
}
</script>
